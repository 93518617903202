import Image from "next/image";
import Link from "next/link";
import Logo from "./../../public/images/1header.svg";
import WhiteLogo from "./../../public/images/1header.svg";
import { v4 as uuidv4 } from "uuid";
// import DarkMode from "../mode/DarkMode";
import jwt from "jsonwebtoken";
import { useRouter } from "next/router";
import {
  isParentPageActive,
  isParentPageActive2,
} from "../../utils/daynamicNavigation";
import { useEffect, useState } from "react";
import WagmiWallet from "../wallet-btn/WagmiWallet";
import { getBalance } from "../../services/services";
import Tippy from "@tippyjs/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { signOut } from "next-auth/react";

const Header01 = ({ user }) => {
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);
  const [isCollapse2, setCollapse2] = useState(null);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });
  const [accountBalance, setAccountBalance] = useState(null);
  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const data = await getBalance();
        const divisor = 10n ** 18n;
        const result = Number(data) / Number(divisor);
        const roundedResult = result.toFixed(4);
        setAccountBalance(roundedResult);
        console.log("Account Balance:", roundedResult);
      } catch (error) {
        console.error("Failed to fetch account:", error);
      }
    };
    fetchBalance();
  }, []);

  const route = useRouter();

  const explorem = {
    id: 3,
    name: "Explore",
    pages: [
      {
        id: uuidv4(),
        name: "ProductGallery",
        icon: "/favicon.png",
        // icon: "/images/MarketNav/box.png",
        path: "/prop/product_gallery",
      },
      {
        id: uuidv4(),
        name: "PropertyWorld",
        icon: "/favicon.png",
        // icon: "/images/MarketNav/home.png",
        path: "/prop/property_world",
      },
    ],
  };

  const explore = {
    id: 3,
    name: "Insights",
    pages: [
      {
        id: uuidv4(),
        name: "Ranking",
        path: "/rankings",
        icon: "/images/insightsSVG/ranking.png",
      },
      {
        id: uuidv4(),
        name: "Activity",
        path: "/activity",
        icon: "/images/insightsSVG/activity.png",
      },
      // {
      //   id: uuidv4(),
      //   name: "About",
      //   path: "/about",
      //   icon: "/images/insightsSVG/about.svg",
      // },
      // {
      //   id: uuidv4(),
      //   name: "Help Center",
      //   path: "/help_center",
      //   icon: "/images/insightsSVG/help.svg",
      // },
    ],
  };

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };
  const mobileCollapse2 = (id) => {
    if (isCollapse2 === id) {
      return setCollapse2(null);
    }
    setCollapse2(id);
  };
  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 4000);
  }, [copied]);

  return (
    <>
      <header className="js-page-header fixed top-0 z-[100] w-full transition-colors bg-[#000000] text-white h-[100px]">
        <div className="flex items-center justify-center h-full px-6 py-5 lg:py-0 xl:px-24 dark:bg-[black]">
          <Link className="shrink-0" href="/">
            {/* <div className="dark:hidden">
              <Image
                src={Logo}
                height={28}
                width={130}
                alt="Xhibiter | NFT Marketplace"
                className="max-h-7 h-auto "
              />
            </div> */}
            {/* <div className="hidden dark:block"> */}
            <Image
              src={"/logo.png"}
              height={28}
              width={110}
              alt="nftOS | NFT Marketplace"
              className="w-[110px] h-auto"
            />
            {/* </div> */}
          </Link>
          {/* <form
            action="search"
            className="relative ml-12 mr-8 hidden basis-3/12 lg:block xl:ml-[8%]"
          >
            <input
              type="search"
              className="text-white bg-transparent border-1 border-accent w-full rounded-2xl py-[0.6875rem] px-4 pl-10 dark:border-accent  dark:placeholder-white placeholder:text-white"
              placeholder="Search"
            />
            <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-white h-4 w-4 "
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
              </svg>
            </span>
          </form> */}
          {/* End Desktop search form */}

          <div className="js-mobile-menu dark:bg-[#0a0a0a] invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
            <nav className="navbar w-full">
              <ul className="flex flex-col lg:flex-row">
                {/* home */}

                {/* page */}

                {/* explore */}

                {/* resource */}

                {/* create */}

                <li className="js-nav-dropdown nav-item dropdown group relative mx-2">
                  <button className="dropdown-toggle border border-accent rounded-lg font-russo text-white flex items-center justify-between py-3.5 text-base lg:px-5 w-full transition ease-in-out duration-300">
                    <span
                      className={
                        isParentPageActive(explore.pages, route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }
                    >
                      Explore
                    </span>
                    <i className="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="h-4 w-4 dark:fill-white"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </button>
                  <ul
                    className="dropdown-menu bg-[#0a0a0a]  -left-6 top-[85%] z-10 hidden grid-flow-col grid-rows-2 gap-x-4 whitespace-nowrap rounded-xl transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:!grid lg:translate-y-4 py-8 lg:px-5 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative"
                    aria-labelledby="navDropdown-1"
                  >
                    {explorem?.pages?.map((page) => (
                      <li key={page.id}>
                        <Link
                          href={page.path}
                          className="hover:bg-jacarta-600 hover:text-accent focus:text-accent flex items-center rounded-xl px-5 py-2 transition-colors"
                          // onClick={() => handleExploreClick(page.id)}
                        >
                          <Image
                            width={24}
                            height={24}
                            src={page.icon}
                            alt={page.name}
                            className="w-4 h-4"
                          />
                          <span className=" mr-3 rounded-xl p-[0.375rem]"></span>
                          <span className="font-russo text-white text-sm dark:text-white">
                            {page?.name}
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>

                <li className="js-nav-dropdown nav-item dropdown group relative mx-2">
                  <Link
                    href="/rankings"
                    className="dropdown-toggle bg-[#0a0a0a] rounded-lg text-white font-russo hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base lg:px-5 w-full"
                  >
                    Ranking
                  </Link>
                </li>

                <li className="js-nav-dropdown nav-item dropdown group relative mx-2">
                  <Link
                    href="/activity"
                    className="dropdown-toggle bg-[#0a0a0a] rounded-lg text-white font-russo hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base lg:px-5 w-full"
                  >
                    Activity
                  </Link>
                </li>
              </ul>
            </nav>
            {/* End menu for desktop */}

            <div className="ml-8 items-center lg:flex xl:ml-12">
              {/* <WalletButton /> */}
              <WagmiWallet user={user} />

              {/* End metamask Wallet */}

              <div className="js-nav-dropdown group-dropdown relative">
                <button className="dropdown-toggle focus-btn-grad group hover-btn-grad ml-2 flex h-10 w-10 items-center justify-center rounded-full border transition-colors hover:border-transparent focus:border-transparent border-transparent bg-white/[.15]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                  </svg>
                </button>
                <div className="dropdown-menu dark:bg-[#0a0a0a] group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-[#4d4d4d] transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0">
                  <div className="w-full flex px-4 mb-4 relative">
                    <Tippy
                      hideOnClick={false}
                      content={copied ? <span>copied</span> : <span>copy</span>}
                    >
                      <button className="js-copy-clipboard px-2 pr-4 dark:text-jacarta-200 max-w-[10rem] text-white rounded-sm select-none overflow-hidden text-ellipsis whitespace-nowrap">
                        <CopyToClipboard
                          text={user?.walletAddress}
                          onCopy={() => setCopied(true)}
                        >
                          <span>{user?.walletAddress}</span>
                        </CopyToClipboard>
                      </button>
                    </Tippy>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      className="dark:fill-jacarta-300 fill-white ml-auto mb-px h-4 w-4 absolute right-8 z-[-1] top-1/2 transform -translate-y-1/2"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M7 7V3a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-4v3.993c0 .556-.449 1.007-1.007 1.007H3.007A1.006 1.006 0 0 1 2 20.993l.003-12.986C2.003 7.451 2.452 7 3.01 7H7zm2 0h6.993C16.549 7 17 7.449 17 8.007V15h3V4H9v3zM4.003 9L4 20h11V9H4.003z" />
                    </svg>
                  </div>
                  <div className="dark:border-jacarta-600 border-jacarta-100 mx-5 mb-6 rounded-lg border p-4">
                    <span className="dark:text-jacarta-200 text-sm font-medium tracking-tight">
                      Balance
                    </span>
                    <div className="flex items-center">
                      <svg className="icon icon-ETH -ml-1 mr-1 h-[1.125rem] w-[1.125rem]">
                        <use xlinkHref="/icons.svg#icon-ETH" />
                      </svg>
                      <span className="text-green text-lg font-bold">
                        {accountBalance}
                      </span>
                    </div>
                  </div>
                  <Link
                    href={`/user/${user?.username}`}
                    className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-accent flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      className="fill-white h-4 w-4 transition-colors dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                    </svg>
                    <span className="font-russo text-white mt-1 text-sm dark:text-white">
                      My Profile
                    </span>
                  </Link>
                  <Link
                    href="/edit-profile"
                    className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-accent flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      className="fill-white h-4 w-4 transition-colors dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M9.954 2.21a9.99 9.99 0 0 1 4.091-.002A3.993 3.993 0 0 0 16 5.07a3.993 3.993 0 0 0 3.457.261A9.99 9.99 0 0 1 21.5 8.876 3.993 3.993 0 0 0 20 12c0 1.264.586 2.391 1.502 3.124a10.043 10.043 0 0 1-2.046 3.543 3.993 3.993 0 0 0-3.456.261 3.993 3.993 0 0 0-1.954 2.86 9.99 9.99 0 0 1-4.091.004A3.993 3.993 0 0 0 8 18.927a3.993 3.993 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.121 3.993 3.993 0 0 0 4 11.999a3.993 3.993 0 0 0-1.502-3.124 10.043 10.043 0 0 1 2.046-3.543A3.993 3.993 0 0 0 8 5.071a3.993 3.993 0 0 0 1.954-2.86zM12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>
                    <span className="font-russo text-white mt-1 text-sm dark:text-white">
                      Edit Profile
                    </span>
                  </Link>
                  <button
                    onClick={() => signOut({ callbackUrl: "/" })}
                    className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-accent flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      className="fill-white h-4 w-4 transition-colors dark:fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z" />
                    </svg>
                    <span className="font-russo text-white mt-1 text-sm dark:text-white">
                      Sign out
                    </span>
                  </button>
                </div>
              </div>
            </div>
            {/* End header right content (metamask and other) for desktop */}
          </div>
          {/* header menu conent end for desktop */}

          <div className="ml-auto flex lg:hidden items-center">
            <WagmiWallet />
            <Link
              href={user?.username ? `/user/${user?.username}` : "/login"}
              className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              aria-label="profile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-white h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
              </svg>
            </Link>
            {/*      <DarkMode />*/}

            <button
              className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              aria-label="open mobile menu"
              onClick={() => setToggle(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
          {/* End header right content  for mobile */}
        </div>
        {/* End flex item */}
      </header>
      {/* main desktop menu end */}

      {/* start mobile menu and it's other materials  */}
      <div
        className={`lg:hidden bg-[#0a0a0a] js-mobile-menu dark:bg-[#0a0a0a] invisible fixed inset-0 z-20 ml-auto items-center opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? "nav-menu--is-open" : "hidden"
        }`}
      >
        <div className="t-0 dark:bg-[#0a0a0a] fixed left-0 z-10 flex w-full items-center justify-between bg-[#000000] p-6 lg:hidden">
          <div className="dark:hidden">
            <Image
              src={Logo}
              height={28}
              width={130}
              alt="Xhibiter | NFT Marketplace"
              className="max-h-7 h-auto "
            />
          </div>

          <div className="hidden dark:block">
            <Image
              src={WhiteLogo}
              height={28}
              width={130}
              alt="Xhibiter | NFT Marketplace"
            />
          </div>

          <button
            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>
        {/* mobile menu top header content */}

        <form action="search" className="relative mt-24 mb-8 w-full lg:hidden">
          <input
            type="search"
            className="text-white placeholder:text-white focus:ring-accent bg-[#4d4d4d] border-none w-full rounded-2xl border py-3 px-4 pl-10 dark:border-transparent dark:bg-white/[.15] dark:placeholder-white"
            placeholder="Search"
          />
          <span className="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-white h-4 w-4 dark:fill-white"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z" />
            </svg>
          </span>
        </form>
        {/* End search form mobile menu  */}

        <nav className="navbar w-full">
          <ul className="flex flex-col lg:flex-row">
            <li className="js-nav-dropdown nav-item dropdown group relative">
              <button
                onClick={() => mobileCollapse(explore.id)}
                className="dropdown-toggle text-white font-russo hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base lg:px-5 w-full"
              >
                <span
                  className={
                    isParentPageActive(explore.pages, route.asPath)
                      ? "text-accent dark:text-accent"
                      : ""
                  }
                >
                  {explore.name}
                </span>
                <i className="lg:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="h-4 w-4 fill-white dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </i>
              </button>
              <ul
                className={`dropdown-menu left-0 top-[85%] z-10 grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl bg-[#4d4d4d] transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-[#0a0a0a] lg:invisible lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${
                  isCollapse === explore.id ? "block" : "hidden"
                }`}
                aria-labelledby="navDropdown-1"
              >
                {explore?.pages?.map((page) => (
                  <li key={page.id} onClick={() => setToggle(false)}>
                    <Link
                      href={page.path}
                      className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-accent flex items-center rounded-xl px-5 py-2 transition-colors gap-5"
                    >
                      <Image
                        width={24}
                        height={24}
                        src={page.icon}
                        alt={page.name}
                        className="w-4 h-4 fill-white"
                      />
                      <span className="font-russo text-white text-sm dark:text-white">
                        {page.name}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li className="js-nav-dropdown nav-item dropdown group relative">
              <button
                onClick={() => mobileCollapse2(explorem.id)}
                className="dropdown-toggle text-white font-russo hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base lg:px-5 w-full"
              >
                <span
                  className={
                    isParentPageActive2(explorem.pages, route.asPath)
                      ? "text-accent dark:text-accent"
                      : ""
                  }
                >
                  {explorem.name}
                </span>
                <i className="lg:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="h-4 w-4 fill-white dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </i>
              </button>
              <ul
                className={`dropdown-menu left-0 top-[85%] z-10 grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl bg-[#4d4d4d] transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-[#0a0a0a] lg:invisible lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${
                  isCollapse2 === explorem.id ? "block" : "hidden"
                }`}
                aria-labelledby="navDropdown-1"
              >
                {explorem?.pages?.map((page) => (
                  <li key={page.id} onClick={() => setToggle(false)}>
                    <Link
                      href={page.path}
                      className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-accent flex items-center rounded-xl px-5 py-2 transition-colors gap-5"
                    >
                      <Image
                        width={24}
                        height={24}
                        src={page.icon}
                        alt={page.name}
                        className="w-4 h-4 m"
                      />
                      <span className="font-russo text-white text-sm dark:text-white">
                        {page.name}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
        {/* End navbar mobile menu  */}

        <div className="mt-10 w-full lg:hidden">
          <div
            className="js-wallet bg-accent shadow-accent-volume hover:bg-accent-dark block w-full rounded-full py-3 px-8 text-center font-semibold text-white transition-all btn-grad
          "
          >
            MetaMask not available :
          </div>
          <hr className="dark:bg-[#0a0a0a] bg-jacarta-100 my-5 h-px border-0" />
          <div className="flex items-center justify-center space-x-5">
            <a className="group">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook"
                className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" />
              </svg>
            </a>
            <a className="group">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="twitter"
                className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
              </svg>
            </a>
            <a className="group">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="discord"
                className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
              >
                <path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z" />
              </svg>
            </a>
            <a className="group">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="instagram"
                className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
              </svg>
            </a>
            <a className="group">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="tiktok"
                className="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" />
              </svg>
            </a>
          </div>
        </div>
        {/* mt-10 w-full lg:hidden */}
      </div>
      {/* End mobile menu and it's other materials */}
    </>
  );
};

export default Header01;
export async function getServerSideProps(context) {
  const { req } = context;
  const token = req.cookies.token;
  const user = token ? jwt.verify(token, process.env.JWT_SECRET) : null;

  return {
    props: { user }, // Will be passed to the page component as props
  };
}
